/* ImageCarousel.css */

/* Style for the entire carousel container */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;  
    /* margin-left: auto;
    margin-right: auto; */
}
  
  /* Style for each slide within the carousel */
  .slick-slide {
    transition: transform 1s ease; /* Slower transition effect */
  }
  
  /* Style for the images within each slide */
  .slick-slide img {
    width: 100%; /* Make the image fill its container */
    margin-left: auto;
    margin-right: auto;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: Add border-radius for rounded corners */
  }
  
  /* Style for the navigation dots */
  .slick-dots {
    bottom: 20px; /* Adjust the distance from the bottom */
  }
  
  /* Style for each navigation dot */
  .slick-dots li button {
    font-size: 12px; /* Adjust the font size of the dots */
    width: 12px; /* Adjust the width of the dots */
    height: 12px; /* Adjust the height of the dots */
    margin: 0 5px; /* Adjust the spacing between the dots */
    border-radius: 50%; /* Make the dots circular */
    background-color: #fff; /* Set the background color of the dots */
  }
  
  /* Style for the active navigation dot */
  .slick-dots li.slick-active button {
    background-color: #000; /* Set a different color for the active dot */
    display: none;
  }

  /* Style for the arrows */
.slick-prev,
.slick-next {
  font-size: 50px; /* Adjust the font size of the arrows */
  color: #000; /* Set the color of the arrows */
  background: none; /* Remove the background */
}

/* Change arrow color on hover */
.slick-prev:hover,
.slick-next:hover {
  /* color: #ff0000; Set the hover color of the arrows */
}
  