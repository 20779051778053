/* Navigation.css */


  .navigation ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
  }
  
  .navigation.visible {
    transform: translateY(0);
  }
  
  .navigation.hidden {
    transform: translateX(+135%);
  }

  /* Navigation.css */

.navigation {
    position: fixed;
    right:50px;
    width: auto;
    top:105px;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent; /* Set to transparent */
    color: white;
    font-size: .9rem;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    border-color: white;
   justify-content: left;
    border: 1;
  }
  
  .navigation ul {
    list-style: none;
    display: inline-grid;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin: 0;
    /* background-color: rgb(0, 0, 0); */
    border-radius: 20px;
    padding: 0;
    }
  
  .navigation li {
    margin-right: 20px;
  }
  
  .navigation a {
    text-decoration: none;
    margin-bottom: 10px;
    text-align: center;
    background: rgba(49,72,255,1);
    color: white;
    width:150px;
    border: 1px solid white; /* Add border */
    padding: .4rem; /* Adjust padding */
    border-radius: 0px; /* Add border-radius for curved edges */
    transition: color 0.5s;
  }

  .navigation a:hover{
    background-color: white;
    color: black;
    cursor: pointer;
  }

  .mb-nav-container {
    visibility: hidden;
    position: fixed;
    transition: .3s;
    top:10px;
    right:10px;
    z-index: 1000;
    align-content:center;}

    .navigation-mb {
      position: fixed;
      width: auto;
      height: 100vh;
      align-content: center;
      margin-left: auto;
      margin-right: auto;
      color: white;
      font-size: .9rem;
      transition: transform 0.3s ease-in-out;
      border: 1;
      background-color: rgba(49, 72, 255, 1);
      z-index: 700;
    }

    .navigation-mb.visible {
      transform: translateY(0);
    }
    
    .navigation-mb.hidden {
      transform: translateX(+135%);
    }
  
    .navigation-mb ul {
      list-style: none;
      flex-wrap: wrap;
      list-style: none;
      width: 100vw;
      display: inline-grid;
      margin-left: auto;
      margin-right: auto;
      height: 100vh;
      background-color: transparent;
      /* position: fixed; */
      margin: 0;
      padding: 0;
      z-index: 5;
      /* padding-top: 50px; */
      /* padding-bottom: 50px; */
      padding-inline-start: 0px;
      margin-block-start: 0px;
        }
      
      .navigation-mb li {
        margin-right: 20px;
      }
      
      .navigation-mb a {
        text-decoration: none;
        margin-bottom: 10px;
        text-align: center;
        background: rgba(49,72,255,1);
        color: white;
        width:150px;
        border: 1px solid white; /* Add border */
        padding: 1rem; /* Adjust padding */
        border-radius: 40px; /* Add border-radius for curved edges */
        transition: color 0.5s;
        height:fit-content;
        align-content: space-around;
      }

      .expanded {
        height:100%;
        background-color: black; /* Set to transparent */
      }
    


@media screen and (max-width:768px) {
  
  /* .button {
    width:25px;
    height: 25px;
    font-size: 80%;
    background-image:url("../images/hamburger.png"),${show ? 'url("../images/cross.png"'};
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border-style: none;
  }  */

  .button {
    width: 25px;
    height: 25px;
    font-size: 80%;
    background-image: url("../images/hamburger.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border-style: none;
  }

  .navigation {
top:180px;
display: none;}

.mb-nav-container{
  visibility: visible;
}
  

  .navigation-mb ul {
    list-style-type: none;  }
  
  .navigation-mb ul li {
  width:-webkit-fill-available;
  text-align: center;
  margin-right:0px;
  min-width: 50vw;
  z-index: 1000;

  }

}
  @media screen and (max-width:465px) {



  }
/* 
  .button {
    width: 25px;
    height: 25px;
    font-size: 80%;
    background-image: url("../images/hamburger.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border-style: none;
  } */
  
  .button.active {
    background-image: url("../images/cross.png");
  }
  
  .mb-nav-container {
    display: flex;
    justify-content: space-between;
  }
  
  .expanded {
    display: none;
  }
  
  .expanded.show {
    display: block;
  }
  

  