.sticker-container {
position: absolute;
width:-webkit-fill-available;
height:auto;
overflow:visible;
/* background-color: rgba(165, 42, 42, 0.431);  */
}

.sticker {
  width:100%;
}

.sticker1 {
position: absolute;
top:200px;
left:10vw;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
height:auto;
width:15rem;
}

.sticker2 {
    position: absolute;
    top:700px;
    left:25vw;
    background-size: cover;
background-position: center center;
background-repeat: no-repeat;
height:auto;
width:15rem;
}

.sticker3 {
   position: absolute;
   top:450px;
   right:10vw; 
   background-size: cover;
background-position: center center;
background-repeat: no-repeat;
height:auto;
width:15rem;
}
.sticker4 {
  position: absolute;
  top: 600px;
  right:30vw;  
  background-size: cover;
background-position: center center;
background-repeat: no-repeat;
height:auto;
width:15rem;
}
.sticker5 {
    position: absolute;
  top: 900px;
  left:10vw;
  background-size: cover;
background-position: center center;
background-repeat: no-repeat;   
height:auto;
width:15rem;
}

@media screen and (max-width: 425px) {
 .sticker1{
    width:8rem;}
    .sticker2{
      width:8rem;}
      .sticker3{
        width:8rem;}
        .sticker4{
          width:8rem;}
          .sticker5{
            width:8rem;}

}
  
