.header-container {
    width:100%;
    min-height:auto;
    position:fixed;
    /* background-color: rgba(0, 0, 0, 0.668); */
    display: flex;
    z-index: 1000;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content:space-between;
  }

.header-nav {
   display: grid;
   padding-left:50px;
   padding-right: 50px;
   text-align: center;
   width: auto;
   /* margin-right:50px; */
}

.header-link{
    text-decoration: none;
    margin-bottom: 10px;
    text-align: center;
    font-size: .9rem;
    background-color: rgba(49,72,255,1);
    color: white;
    width:150px;
    border: 1px solid white; /* Add border */
    padding: .4rem; /* Adjust padding */
    border-radius: 0px; /* Add border-radius for curved edges */
    transition: color 0.5s;
}

.header-link:hover{
    color: black;
    background-color: white;
}

.heading {
    color: blanchedalmond;
    font-size:x-large;
    padding: 5px;
    text-decoration: none;
}


.heading-mb {
  color: blanchedalmond;
  font-size: 5rem;
  padding-right: 20px;
  padding: 0px;
  max-width: 60px;
  text-decoration: none;
  z-index: 1000!;
  margin-top: 50px;
}
@media screen and (max-width: 768px) { 
    .header-container {
        display: grid;
        /* background-color: rgba(0, 0, 0, 0.523); */
      }
    
      .header-link {
        margin-left: 0px;
        margin-right:10px;
        /* background-color: black; */
      }
    
    }
      @media screen and (max-width: 425px) {

.header-container{
    background-color: rgba(0, 0, 0, 0.409);
    border-bottom: 1px white solid;
    display:flex;
    padding-bottom: 0px;
      }


        .header-nav{
            display: flex;
            text-align: center;
            margin-bottom: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .header-link {
            width:100px;
            height:fit-content;
        }

        .heading {
           text-align: left;
           margin-left: 10px;
           padding: 0px;
           text-decoration: none;
        }

    }
    