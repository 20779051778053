.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@font-face {
  font-family: Comfortaa;
  src: url(../fonts/Comfortaa-Regular.ttf);
  font-weight: regular;
}

@font-face {
  font-family: SpaceMono;
  src: url(../fonts/SpaceMono-Regular.ttf);
  font-weight: regular;
}

@font-face {
  font-family: SchoonSquare;
  src: url(../fonts/SchoonSquare-Regular.ttf);
  font-weight: regular;
}

@font-face {
  font-family: Lekton;
  src: url(../fonts/Lekton-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Merriweather-Bold;
  src: url(../fonts/Merriweather-Bold.ttf);
  font-weight: bold;}

  @font-face {
    font-family: Merriweather-Reg;
    src: url(../fonts/Merriweather-Regular.ttf);
    font-weight: bold;}

    @font-face {
      font-family: Montserrat-bold;
      src: url(../fonts/Montserrat-VariableFont_wght.ttf);
      font-weight: bold;}

      @font-face {
        font-family: Montserrat-med;
        src: url(../fonts/Montserrat-Medium.ttf);
        font-weight: bold;}

@font-face {
  font-family: Lekton-regular;
  src: url(../fonts/Lekton-Regular.ttf);
}

@font-face {
  font-family: Lekton;
  src: url(../fonts/Lekton-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: Kyiv;
  src: url(../fonts/KyivTypeTitling-Bold3.otf)
}

.par-spacer {
margin-top:10vh;
}

.index {
  width:auto;
  z-index: 2;
}

.index-home {
  z-index: 2;;
 width: auto;
}


.stickers1 {
  background-image: url("../images/stickersheet1.png");
  background-size:cover;
  background-position: center center;
  background-repeat: no-repeat;
 
  }


.stickers2 {
  background-image: url("../images/stickersheet2.png");
  background-size: cover;
  background-position: 0px center;
  background-repeat: no-repeat;
  width:100%;
  height:100%;
}


.stickers3{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../images/stickersheet3.png")
}

.stickers4{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../images/stickersheet4.png")
}

.stickers5{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../images/stickersheet5.png");
  z-index:2;
}

.body {
  /* padding-top:50vh; */
  /* transform:scale(.6); */
  zoom:0.7;
  top: 0px;
  /* padding-top:5vh; */
}

ul {
  list-style-type: none;
  padding-left: 0;
}
.App-header {
  background-color: #1f64ef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.guide-title {
  font-size: 5rem;
  text-align: left;
  font-weight: 450;
  color: #fff;
  padding:3rem;
  margin-top:0;
  position: relative;
  font-family: Lekton-regular;
  width: 60vw;
  margin-left: 20vw;
  margin-right: auto;
  margin-top:0vh;}

.guide-intro {
  font-size: 2.3rem;
  text-align: left;
  color: #fff;
  width: 55rem;
  margin-left: 20vw;
padding-bottom:60px;
  /* margin-bottom: 7rem; */
  /* font-family: Kyiv; */
  /* font-family: Montserrat; */
  font-family: Lekton-regular;
  /* background-color: hsla(0, 0%, 0%, 0.117); */;  
  /* border-radius: 70px; */
  transition:.4s;
  padding: 3rem;
  border-radius: 70px;
  z-index: 1;
}

.guide-intro:hover{
  /* background-color: #00000083; */
}

.magicpattern-11 { 
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(240 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%23e5ffbc%22%2F%3E%3Cstop offset=%22100%25%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M748.5 596Q562 692 434 666.5t-199-218Q164 256 388 205t385.5 122q161.5 173-25 269Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M748.5 596Q562 692 434 666.5t-199-218Q164 256 388 205t385.5 122q161.5 173-25 269Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern-1 { 
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CradialGradient id=%22b%22 r=%22100%25%22 cx=%22125%25%22 cy=%2255%25%22%3E%3Cstop offset=%220%25%22 stop-color=%22%23e5ffbc%22%2F%3E%3Cstop offset=%22100%25%22%2F%3E%3C%2FradialGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M748.5 596Q562 692 434 666.5t-199-218Q164 256 388 205t385.5 122q161.5 173-25 269Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M748.5 596Q562 692 434 666.5t-199-218Q164 256 388 205t385.5 122q161.5 173-25 269Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern-2 { 
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M805 650.5Q674 801 498 804.5T161 654Q0 500 175 370t358.5-187.5q183.5-57.5 293 130t-21.5 338Z%22%2F%3E%3C%2FclipPath%3E%3Cpattern id=%22b%22 patternUnits=%22userSpaceOnUse%22 width=%2227.5%22 height=%2227.5%22 viewBox=%220 0 100 100%22 fill=%22%23ffe2a7%22%3E%3Ccircle cx=%2250%22 cy=%2250%22 r=%2212.5%22%2F%3E%3C%2Fpattern%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M805 650.5Q674 801 498 804.5T161 654Q0 500 175 370t358.5-187.5q183.5-57.5 293 130t-21.5 338Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern-3 { 
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M778.5 663Q688 826 502 822T250 659q-66-159-27-365t243-147.5q204 58.5 303.5 206t9 310.5Z%22%2F%3E%3C%2FclipPath%3E%3Cpattern id=%22b%22 patternUnits=%22userSpaceOnUse%22 width=%2240%22 height=%2240%22 viewBox=%220 0 100 100%22 fill=%22%23fff9ec%22%3E%3Ccircle cx=%2250%22 cy=%2250%22 r=%2212.5%22%2F%3E%3C%2Fpattern%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M778.5 663Q688 826 502 822T250 659q-66-159-27-365t243-147.5q204 58.5 303.5 206t9 310.5Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern-5 { 
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22b%22%3E%3Cpath fill=%22currentColor%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2FclipPath%3E%3Cfilter id=%22a%22 x=%22-50vw%22 y=%22-50vh%22 width=%22100vw%22 height=%22100vh%22%3E%3CfeFlood flood-color=%22%23fff%22 result=%22neutral-gray%22%2F%3E%3CfeTurbulence type=%22fractalNoise%22 baseFrequency=%222.5%22 numOctaves=%22100%22 stitchTiles=%22stitch%22 result=%22noise%22%2F%3E%3CfeColorMatrix in=%22noise%22 type=%22saturate%22 values=%220%22 result=%22destaturatedNoise%22%2F%3E%3CfeComponentTransfer in=%22desaturatedNoise%22 result=%22theNoise%22%3E%3CfeFuncA type=%22table%22 tableValues=%220 0 0.3 0%22%2F%3E%3C%2FfeComponentTransfer%3E%3CfeBlend in=%22SourceGraphic%22 in2=%22theNoise%22 mode=%22soft-light%22 result=%22noisy-image%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3Cg filter=%22url(%23a)%22 clip-path=%22url(%23b)%22%3E%3Cpath fill=%22%23373ebe%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern-4 { 
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23373ebe%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern-6 { 
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(45 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%238471ff%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22%23fff%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern-7 { 
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(45 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%238471ff%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22%23004f80%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.magicpattern-8 { 
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(-45 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%230d0065%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22rgba(49%2C72%2C255%2C1)%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
} 

.magicpattern-9 { 
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(-45 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%230d0065%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22rgba(49%2C72%2C255%2C1)%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M865 629Q758 758 629 803.5T383.5 791q-116.5-58-215-174.5T147 362q77-138 215-195.5t266.5 9.5q128.5 67 236 195.5T865 629Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M865 629Q758 758 629 803.5T383.5 791q-116.5-58-215-174.5T147 362q77-138 215-195.5t266.5 9.5q128.5 67 236 195.5T865 629Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

/* 

.blob-container{
  position: relative;
  height:100px;
}

.blob-wrapper {
  position: absolute;
} */




.blob {
  height: 700px;
  width: -webkit-fill-available;;
  position:fixed;
  right:10vh;
  top:30vh;
  padding: 0px;
  margin: 0px;
  padding-top: 0px;
  overflow: hidden;
}

.blob-2 {
  height: 400px;
  position:fixed;
  left:10vh;
  top:5vh;
  padding: 0px;
  margin: 0px;
  padding-top: 0px;
  width: -webkit-fill-available;
}


.title-2 {
  font-size: 2.5rem;
  margin:0rem;
  text-align: center;
  font-family: Merriweather-reg;
  color:#ffffff;
  margin-bottom: 8rem;
  margin-top:3vh;
  width:40rem;
  margin-left: auto;
  margin-right: auto;

}

.guide-prompt {
  font-size: 4rem;
  color: rgb(251, 218, 190);
  text-align: center;
  font-family: Merriweather-reg;
  padding-top: 0px;
  padding-bottom: 10px;
  margin-top: 0;
  width:60rem;
  margin-left: auto;
  margin-right: auto;
  height: 40rem ;
  top: 20vh;
  position: relative;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  position:fixed;
  right: 5px;
}

.box-3{
  height:auto;
  width:320px;
  position: static;
  bottom: 0px;
  margin-right:auto;
  margin-left: auto;
  flex-wrap: wrap;
  color: #E3FFD6;
  font-size: 1rem;
  font-family:Lekton-regular;
  /* float:right; */
  z-index: 1;
position: relative;
text-align: center;
text-decoration: none;
}

.box-4{
    height:auto;
    width:auto;
text-align: right;    
position: static;
    bottom: 0px;
    margin-right:0px;
    flex-wrap: wrap;
    color: #E3FFD6;
    font-size: 1rem;
    font-family:Lekton-regular;
    float:right;
    z-index: 1;
  position: relative;
  }


.box-2{
  height:auto;
  width:320px;

  bottom: 0px;
  margin-right:0px;
  flex-wrap: wrap;
  color: #E3FFD6;
  font-size: .8rem;
  font-family:Lekton-regular;
  z-index: 1;
position: relative;
align-items: end;
}

.background-test{
  background-color: red;
}

.social-contain {
    flex-direction: row;
  justify-content: space-between;
  margin-bottom:5px;
  text-align:right;
  /* float: right; */
}

.social-contain-2 {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
  width:auto;
  margin-bottom:5px;
  /* float: right; */
}

.social{
width:auto;
text-align: center;
}

.social:hover {
text-decoration: underline;
cursor: pointer;
font-weight: bold;
}

.footer-title {
  color:#E3FFD6 ;
  margin:0px;
}

.footer-line {
  margin:0px;
  color: #E3FFD6;
  text-decoration: none;
}

.footer-line-link:hover{
text-decoration: underline;
font-weight: bold;
}

.container-1{
  margin-top:0vh;
  margin-bottom: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-end {
  padding-left: 40px;
  padding-right:40px;
  padding-top:20px; 
  /* border-top: 3px #E3FFD6 solid; */
  z-index: 1;
  position: relative;
}

.end-container{
  bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left:0px;
}

.end-div{
  display: flex;
  justify-content: space-between;
  bottom:0px;
  margin-top:10vh;
}

.credit{
  color: white;
  font-family: Lekton-regular;
  font-size: .7rem;
  text-align: left;
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: .0rem;
  align-self: flex-end;

}
.credit-link {
  position: relative;
  font-family: Lekton-regular;
  margin-left: 5px;
  align-content: center;
  color: white;
  display: block;
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 1em;
    margin-inline-end: 1em;
    margin-left:5px;
    margin-right:5px;
    border: none;
    text-decoration: underline;
    font-weight: bold;
}

.credit-link2 {
  position: relative;
  font-family: Lekton-regular;
  font-size: .9rem;
 vertical-align: middle;
    border: none;
    text-decoration: none;
    text-align: center;
}

.credit-list {
  align-self: end;
}



.credit-link:hover {
text-decoration: underline;
font-weight: bold;
}

.dev{
  display:flex;
  align-self: flex-end;
}

.credit-2{
  color: white;
  font-family: Lekton-regular;
  text-decoration: underline;
  font-size: .7rem;
}

.side:hover {
  border-bottom: white solid 1px;
  font-style: bold;
  cursor: pointer;
}

.action {
height:100px;
/* border: white 2px solid; */
border: #E3FFD6 2px solid;
font-family:SpaceMono;
font-size: 1.2rem;
vertical-align: middle;
background: none;
color: #E3FFD6;
margin-bottom:20px;
margin-top:20px;
margin-left: auto;
margin-right: auto;
text-align: center;
display: grid;
place-items: center;
}

.action:hover {
  color: black;
  background-color: white;
cursor: pointer;
border:white;
}

.img-contain{
  /* padding: 40px; */
  width: 100%;
  margin-left:auto;
  height:auto;
  margin-right:auto;
  position: relative;
  overflow: hidden;
  /* -webkit-filter:grayscale(70%); */
}

.img-contain-mb{
  /* padding: 40px; */
  width: 100%;
  margin-left:auto;
  height:auto;
  margin-right:auto;
  position: relative;
  overflow: hidden;
  display:none;
  /* -webkit-filter:grayscale(70%); */
}

.mux-player {
  display: ;
}

.align-left{
  position: relative;
  margin-left:0px;
  margin-right:auto;
}

.align-right{
  position: relative;
  margin-left:auto;
  margin-right:0px;
}
.detour-vid{
  width:700px;
  height:auto;
  border-style: solid;
  position: relative;
  border:white;
  border-width: thin;
  margin-bottom: 50px;
  border-radius: 30px;
  z-index: 1;
}

.detour-contain{
  padding:20px;
}


.paragraph {
  font-family: Comfortaa;
  font-size: 1.8rem;
  text-align: left;
  padding:10px;
  line-height: 2.7rem;
}

.paragraph-title{
  font-weight: 1000;
  font-size: 4rem;
  font-family:SpaceMono;
  width:auto;;
  flex-wrap: wrap;
}

.paragraph-subtitle{
  font-weight: 1000;
  font-size: 2rem;
  font-family:SpaceMono;
  width:auto;;
  flex-wrap: wrap;
}

.white-text{
  color: white;
}

.header {
  height: 130vh;
  background-color: transparent;
  padding: 5rem;
  margin-top: 0;
  top:13vh;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 70px;
  /* filter: drop-shadow(20px 0px 50px rgb(255, 255, 255)); */
  position: relative;
  font-size: 12rem;
  color: white;
  /* color:#E3FFD6;  */
  /* font-family: Montserrat-med; */
  font-family:SchoonSquare;
  text-align: center;
display: flex;
flex-wrap: wrap;
justify-content: center;
vertical-align: middle;
align-items: center;

}

.section-title {
  font-size: 2rem;
}

.background-dg{
  background-color: #031401;
}

.background-db {background-color: #0D0065;}

.background-db2 {background-color:#0d006583}

.background-dr{
  background-color: #140101;
  /* background-color: #290000; */
}




.section-ab{
  padding-top: 5vh;
  padding-bottom: 10vh;
  border-radius: 70px;
  position: absolute;
   min-height: 1000px; 
  }

.flex {
  display: flex;
}

.background-purp {
  background-color: hsl(256, 100%, 50%);
}

.background-green {
  background-color: #E3FFD6;
}

.background-white{
  background-color: hsl(0, 0%, 100%);
  color: black;
  border-color: black;
}

.background-w2{
  background-color: hsl(0, 0%, 100%);
  color: black;
  border-color: black;
}

.background-b{
  background-color: hsl(0, 0%, 0%);
  z-index:1;
}


.h1 {
  font-size: 12rem;
}

.get-started-button {
  width:auto;
  height:auto;
  bottom:0px;
  position: relative;
  margin-top: 10vh;
  margin-right: auto;
  margin-left:auto;
  font-size: 1.5rem;
  font-family: SpaceMono;
  /* padding: 40px; */
  border-radius: 80px;
  color: rgb(0, 0, 0);
  background-color: #ffffffee;
  transition: .3s;
  z-index: 1;
}

.button-text{
  margin-top: auto;
  margin-bottom: auto;
  padding: 40px;
}

.get-started-button:hover{
  background-color:rgb(168, 168, 168);
  cursor: pointer;
}


.footer-container {
padding-top: 5vh;
padding-bottom: 10vh;
border-radius: 70px;
position: relative;
margin-left:10rem;
margin-right:10rem;
margin-bottom: 0rem;
 /* min-height: 1000px;  */
}

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap");
.App-header {
  font-size: 1rem;
  text-align: center;
  font-family: "Oswald", sans-serif;
  padding-bottom: 2rem;
}

.active {
  background-color: #fa2020;
}



.bottom{
  padding-bottom:10vh;
}

.text-container{
  width: 60vw;
  margin-left:auto;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding: 10px;
  margin-right:auto;
}

.video-container{
  width: 60vw;
  margin-left:auto;
  padding: 10px;
  margin-right:auto;
  position: relative;
}
.text-container:hover{
 background-color: rgba(0, 0, 0, 0.718);
 z-index: 5;
 transition: .3s;
 border-radius: 40px;

}

.video1 {
  width: 15rem;
  height:10rem;
  background-color: #fa2020;
}

.video2 {
  width: 15rem;
  height:10rem;
  background-color: #3fae5b;
}

.footer-text {
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0px;
  font-family: Lekton-regular;
color: white;
font-size: 2.5rem;}

.button-container {
  width:40rem;
  margin-left:auto;
  margin-right:auto;
  margin-top: 5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  column-gap: 2rem;
}

.button-bottom {
  width: 13rem;
  height:3rem;
  background-color: #E3FFD6;
  border-radius: 40px;
  font-family: Comfortaa;
  font-size: 90%;
  border: none;
}

.button-bottom:hover {
  background-color: #ffffff;
  cursor: pointer;
}

.mb-intro{
  height: auto;
  /* background-color: #00000026; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 100px;
  padding-left: 50px;
  padding-right:50px;
  margin-bottom: 20px;
  display:none;
}

.nax-text {
  font-size: 2rem;
  color: #282c34;
}

.home-subt {
  font-size: 6rem;
  font-family:SpaceMono;
  text-align: left;
  max-width: 60vw;
}

.title-container {
  margin-top: 0vh;
  /* padding-bottom: 5rem; */
  /* min-height:100vh; */
  /* background-color:#3d3c2f; */
} 

/* .mux-contain {
  padding: 40px;
} */

/* Colours */

.gradient-1{
  background: rgb(61,60,47);
background: linear-gradient(180deg, rgba(61,60,47,1) 0%, rgba(0,0,0,1) 13%);
}

.gradient-2{
  background: hsl(256, 100%, 50%);;
  background: linear-gradient(180deg, hsl(256, 100%, 50%) 0%, rgba(0,0,0,1) 13%);
}

.gradient-3{
  background: rgb(4, 0, 255);
  background: linear-gradient(180deg, rgb(3, 0, 191) 0%, rgb(0, 0, 0) 13%);
}

.gradient-4{
  background: rgb(255, 0, 0);
  background: linear-gradient(180deg, rgb(255, 0, 0) 0%, rgb(0, 0, 0) 13%);
}

.gradient-5{
  background: rgb(20, 123, 155);
  background: linear-gradient(180deg, rgb(20, 123, 155) 0%, rgb(0, 0, 0) 13%);
}

.gradient-6{
  background: rgb(109,194,255);
  background: linear-gradient(176deg, rgba(109,194,255,1) 0%, rgba(49,72,255,1) 83%);
}

.background-blue{
  background-color: rgba(49,72,255,1);
}

.background-blue2{
  background-color: rgba(49, 73, 255, 0.795);
}

.background-cont {
  background-color: hsl(0, 0%, 100%)
}

.background-sand {
  background-color:#3d3c2f
}

.background-purp {
  background-color: hsl(256, 100%, 50%);
}

.background-green {
  background-color: #E3FFD6;
}

.background-white{
  background-color: hsl(0, 0%, 100%);
}

.background-b{
  background-color: hsl(0, 0%, 0%);
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
}

/* Page Masters */

    .home-end-container{
      display: inline-grid
    }

    .intro-container{
      padding-top:25vh;
    }

    .grid {
      display: flex;
      padding-left: 1rem;
      padding-right: 1rem;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .section-home1{
      padding-top: 5vh;
      padding-bottom: 5vh;
      border-radius: 70px;
      position: relative;
      /* margin-left:10rem;
      margin-right:10rem; */
      margin-top: 0px;
      height:auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      }

      .title {
        font-size: 4rem;
        margin:0rem;
        text-align: center;
        /* font-family: SchoonSquare; */
        /* font-family: Comfortaa; */
        font-family:SpaceMono;
        color:#ffffff;
        margin-top: 10vh;
        margin-bottom: 10vh;
      }

      .section{
        padding-top: 10vh;
        padding-bottom: 10vh;
        border-radius: 70px;
        position: relative;
        margin-top: 0px;
         /* min-height: 1000px;  */
        }

        .title-text{
          width:70%;
          margin-left: auto;
          margin-right: auto;
        }
        .white {
          color: white;
        }

        .section-box{
          border-radius: 70px;
          width:80vw;
          position: relative;
          margin-left:auto;
          margin-right:auto;
          margin-bottom:10vh;
          display: table;
          }
        
        .card-section{
          padding-bottom: 5vh;
          border-radius: 70px;
          position: relative;
          column-gap: 2rem;
          margin-bottom: 5rem;
          margin-top: 20vh;
          row-gap: 2rem;
          display: flex;
          flex-wrap: wrap;
         justify-content: center;
         
           /* min-height: 1000px;  */
          }

/* Card Master*/

          .card-container{
            font-family: Comfortaa;
            font-size: 1.5rem;
            margin-left:auto;
            margin-right:auto;
            text-align: left;
            color: rgb(255, 255, 255);
            padding-bottom: 10vh;
            padding: 3rem;
            max-width: 95vw;
            margin-top:10vh;
            border-radius: 40px;
            filter: drop-shadow(55px 55px 50px #0000006a);
            border: 1px white solid;
            z-index: 4;
            /* text-shadow: 0 0 12px #000; */
          }

          .card-container-pages{
            font-family: Comfortaa;
            font-size: 1.5rem;
            margin-left:auto;
            margin-right:auto;
            text-align: left;
            color: rgb(255, 255, 255);
            padding-bottom: 10vh;
            padding: 3rem;
            max-width: 95vw;
            margin-top:10vh;
            border-radius: 40px;
            filter: drop-shadow(55px 55px 50px #0000006a);
            border: 1px white solid;
            z-index: 4;
            /* text-shadow: 0 0 12px #000; */
          }

          .no-border{
            border: none;
          }

          .backnext-link{
            text-decoration: none;
            font-family: SpaceMono;
            color: black;
          }

          .card-container:hover{
            /* background-color: white; */
            transition: .6s;
          }

          .card-container-pages:hover{
            /* background-color: white; */
            transition: .6s;
          }

          .card-para {
            padding: 50px;
            border: 1px solid white;
            width:70%;
            /* background-color: black; */
          }

          .card-wide {
            width:45rem;
            margin-left: 3rem;
            margin-right: 3rem;
          }

          .card-wider{
            width:75rem;
          }

          .card-narrow{
            width:40rem;
          }

          .card-narrower{
            width: 30rem;
          }

          .align-c{
            text-align: center;
          }

         
          .card-left {
            position:relative;
            left:0px;
          z-index:1;
          width:75rem;
        }

            .card-right {
              position:relative;
            right:0px;
          z-index:1;}

            .behind {
              z-index: 2;
              height:55rem;
            }

            .card-middle{
              position:relative;
              margin-left:auto;
              margin-right:auto; 
              z-index: 1;
            }

            .second-card{
              top: -5em;
              position: relative;
              margin-left: auto;
              margin-right:auto;
              z-index:1;
              padding-top: 10rem;
            }

            .first-card{
              z-index:2;
              position: relative;
            }

            .grid-container{
              flex-grow:2;
            }

            .grid-spacer{
              min-height:45rem;
              vertical-align: middle;
            }

            .bottom-left{
              position: absolute;
              left:0px;
              bottom:0;
            }

/* Home Page */

          .card-what {
            width:100vw;
            border-radius: 0px;
            filter: drop-shadow(25px 25px 60px #ffffff5e);
            font-size: 1.5rem;
            position: relative;
            margin: 2rem;
            border-radius: 80px;
            min-width: 40rem;
            color: white;
            border: 4px solid white;
          }

          .home-paragraph {
            max-width:50vw;
            border: 1px white solid;
            padding:50px;
            font-size: 1.8rem;
            line-height: 3rem;
          }

          .card-cont {
            width:25rem;
            position: relative;
            font-size: 1.2rem;
            border-radius: 70px;
            filter: drop-shadow(25px 25px 60px #ffffff5e);
            text-align: center;
            margin:2rem;
            min-width:25rem;
          }

          .close-gap{
            margin-top:-15vh;
          }

          .card-this {
            width:45rem;
            border-radius: 70px;
            filter: drop-shadow(25px 25px 60px #ffffff5e);
            font-size: 1.5rem;
            position: relative;
            margin: 2rem;
            min-width: 40rem;
            min-height:25rem;
          }

/* Elements */
      /* Contents Section */

      .video-container{
        width:100vw;
        border-radius: 70px;
        margin-left: auto;
        margin-right: auto;
      }

      .cont-item{
        width:auto;
        height:auto;
        padding:20px;
        /* background-color: #ffffff; */
        background-color:#0000001b;
        border-radius: 20px;
        margin-bottom: 10px;
        text-align: left;
        transition: 0.3s;
        }
        
        .cont-item:hover {
          background-color: #00000075;
        }

.bold{
  font-weight:900;
  text-decoration-line: underline;
}

.open-gap {
  margin-top:15vh;
}

.card-quote {
  font-family: Comfortaa;
  font-style: italic;
  font-size:4em;
  position: relative;
  margin-top:6vh;
  margin-bottom:6vh;
  padding: 40px;
  border-radius: 70px;
  color: white;
  width:75%;
  margin-left:auto;
  margin-right: auto;
  z-index:1;
}

.quote-right{
  float:left;
}

        /* Checkboxes */

.cb-question{
  font-family: Lekton-regular;
  font-size:3rem;
  margin-top:0px;
  margin-bottom:20px;
}

.cb-answer{
  font-family: Comfortaa;
  font-size:1.5rem;
  margin-top:0px;
}

.cb-text{
  font-family: Comfortaa;
  font-size: 1.8rem;
  margin-top: 0px;
  max-width: 18rem;
  margin: auto;
  text-align: center;
}

.cb-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  justify-content: center;
}

.background-b2{
  background-color: hsla(0, 0%, 22%, 0.538);
}

.home-subt-2 {
  font-size: 4rem;
}

.cb-div {
  font-family: Kyiv;
  /* background-color: hsl(0, 0%, 0%); */
/* width:calc(100% * (1/3)); */
  display: flex;
  flex: 0 0 30rem;
  /* min-height: 500px; */
  min-width:40rem;
  border-radius: 70px;
  padding-top: 2rem;
  position: relative;
  filter: drop-shadow(25px 25px 10px #00000032);
  overflow: hidden;
 padding: 500px;
  color: #ffffff;
  /* border: 1px solid white; */
  margin: 2rem;
  z-index: 1;
  column-gap: 30px;

  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(-45 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%230d0065%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22rgba(49%2C72%2C255%2C1)%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M853 629.5q-94 129.5-223.5 198T358 840q-142-56-178.5-198t0-284Q216 216 358 124t270.5 13.5Q757 243 852 371.5t1 258Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");

}

.cb-div-2 {
  /* font-family: Kyiv; */
  font-family: Merriweather-reg;
  font-size: 1.2rem;
  margin: 30px;
  background-color: hsla(0, 0%, 0%, 0.634);
  display: flex;
  flex: 0 0 30rem;
  min-height: 200px;
  min-width:7400px;
  border-radius: 70px;
  padding: 1rem;
  position: relative;
  filter: drop-shadow(25px 25px 10px #0000006a);
  overflow: hidden;
}
        .q-a {
          margin:20px;
        }
        
        .checkbox {
          min-width: 100px;
          height:5rem;
          top: 0px;
          align-items: start;
          margin-right: auto;
          vertical-align: top;
          font-size: 5rem;
        }

/* Cards */

.card-1 {
  width:40rem;
  min-height: 30rem;
  border-radius: 70px;
  filter: drop-shadow(25px 25px 60px #0000006a);
}

.card-2 {
  width:30rem;
  border-radius: 70px;
  filter: drop-shadow(25px 25px 40px #0000006a);
  top:28rem;
  position: absolute;
  left:40vw
}

.card-3 {
  width:50rem;
  border-radius: 70px;
  filter: drop-shadow(25px 25px 40px #0000006a);
  top:28rem;
  position: absolute;
  left:20vw
}

.cards {
  padding-left:10rem;
  margin-bottom: 55vh;
}

.after-cards {
  top: 60vh;
}



/* General styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Font imports and definitions */

/* Responsive adjustments */
@media screen and (max-width: 768px) {

.button-text{
padding: 15px;
font-size: 1.2rem;
margin-left: auto;
margin-right:auto;
bottom:30px;
}


.get-started-button {
  margin-left: auto;
  margin-right:auto;
  position: relative;
}

  .home-subt {
    font-size: 3rem;
    line-height: 4rem;
    margin-left: auto;
    margin-right: auto;
   }

 .page-end {
  padding-top:10vh;
 }
 
.cb-question {
  font-size: 2rem;
}

 .credit-link{
  margin-left: 7px;
 }
  .card-left {
  margin-left: auto;
  margin-right:auto;
  right:0px;
  left:0px;
 }

 



.checkbox {
  display: none;
}

 .card-container { 
  font-size:1.2rem;
  line-height: 2rem;
  margin-bottom: 20px;
  width:80%;
 }

 .card-container-pages { 
  font-size:1.2rem;
  line-height: 2rem;
  margin-bottom: 20px;
  width:80%;
 }

 .blob-2 {
  opacity:.7;
}


 .paragraph-title {
  font-size:2rem;
  line-height: 3rem;
  text-align: left;
  width:fit-content;
 }

 .card-right {
  margin-left: auto;
  margin-right:auto;
 }

 .card-middle{
  width:fit-content;
 }


  .guide-title {
    font-size: 3rem;
    width: 80vw;
    margin-left: 10vw;
    margin-right: auto;
    margin-top: 5vh;
  }

  .guide-intro {
    font-size: 1.5rem;
    width: 90%;
    margin-left: 5%;
    padding: 2rem;
  }

  .blob {
    display: block;
  }

.home-paragraph{
  font-size: 1.1rem;
  text-align:left;
  padding: 0px;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 0px 0px 0px 0px;
  display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    max-width:fit-content;
    margin-bottom: 70px;
  
}



  .blob-2 {
    display: block;
  }

  .title-2 {
    font-size: 2rem;
    margin-top: 3vh;
    margin-bottom: 5vh;
    width: 80%;
    margin-left: 10%;
    margin-right: auto;
  }

  .guide-prompt {
    font-size: 2rem;
    width: 80%;
    margin-left: 10%;
    margin-right: auto;
    height: auto;
    top: auto;
    position: relative;
    margin-top: 5vh;
    margin-bottom: 5vh;
    position: relative;
    right: auto;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .footer-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .footer-text {
    font-size: 1.5rem;
    width: 90%;
    margin-left: 5%;
    margin-right: auto;
  }

  .button-container {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    column-gap: 1rem;
    row-gap: 1rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }
  

  .video-container {
    width: 90%;
    margin-left: 5%;
    margin-right: auto;
  }

  .cont-item {
    width: 90%;
    margin-left: 5%;
    margin-right: auto;
  }

  .cb-div {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    min-width: auto;
    flex: auto;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-size: .7rem;
    margin:20px;
    background-image: none;
    border: 1px solid white;
    
  }

  .check-info{
    font-size: 1.2rem;
    padding: 20px;
    width: 80%;
  }

.detour-vid {
  width:auto;
}


  .card-container {
    margin-left:auto;
    margin-right:auto;
  }

  .card-container-pages {
    margin-left:auto;
    margin-right:auto;
  }

  .card-quote {
    font-size: 1.8rem;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .card-this {
    width: 90%;
    min-width: auto;
    margin-left: 5%;
    margin-right: auto;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .section-home1 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .section-box {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .card-left {
    right: auto;
  }

  .card-right {
    left: auto;
  }

  .second-card {
    top: auto;
    padding-top: 3vh;
  }

  .grid-spacer {
    min-height: auto;
  }

  .grid {
    padding: 0px;  }

  .end-div{
    display: inline-block;
  }

  .credit {
    line-height: normal;
  }

  .card-wider {
    width:auto;
  }

  .header-container {
    visibility: hidden;
  }

}

/* Additional media queries can be added for further adjustments */


@media screen and (max-width: 450px) {

  .card-wider{
    width:auto;
  }

  .card-container{
    width: fit-content;
     margin-left:20px;
    margin-right:20px;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .card-container-pages{
    width:auto;
     margin-left:20px;
    margin-right:20px;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .home-paragraph {
    font-size:1.5rem;
  }

  .card-para {
font-size: 3rem;
  }

  .index-home {
    padding-bottom: 60px;
   width: auto;
  }

  .img-contain{
    min-height:0;
    display:none;
  }
  

  .mb-intro{
    display: block;
  }

  .intro-container{
    padding-top:10vh;
  }

.index{
  padding-bottom: 60px;
 width:auto;
 overflow-x: hidden;
}

.guide-intro{
font-size:2rem;
}

.guide-title {
  width: auto;
 
}

.img-contain-mb{
  min-height:0;
  display:block;
  margin-bottom: 20px;
}



  .home-subt {
    font-size: 2rem;
    width:100%;
    line-height: 2rem;
    margin-left:0x ;
    /* width: 100vw; */
    left:0px;
    max-width:100vw;
   }
  
   .guide-intro {
    width:90%;
   }
  
.button-container{
display: flex;
justify-content: center;
flex-wrap: wrap;
  }

.button {
  width:8rem;
}

.footer-text {
  font-size:2rem;
}

.grid {
  display: flex;
  padding: 0px;
}

.card-wide {
  margin-bottom: 30px;

}

.paragraph {
  font-family: Comfortaa;
  font-size: 1.3rem;
  text-align: left;
  padding:10px;
  line-height: 2.7rem;
}

.text-container {
  width:80%;
}

.card-para {
  font-size: 1.5rem;
  line-height: 3rem;
  border:none;
  background-color: none;
  width:auto;
  padding: 0px;
  margin-left:auto;
  margin-right:auto;
}

  .background-blue2{
    background-color: rgba(49, 73, 255, 0);
  }

  .background-db2{
    background-color: rgba(49, 73, 255, 0);
  }

}
